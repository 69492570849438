import { storyblokEditable, SbBlokData } from '@storyblok/react';

import CtaButton, { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import StoryblokLink from '../../components/StoryblokLink/StoryblokLink';

export interface TutorJobCardBlokProps extends SbBlokData {
  clickable?: 'enabled' | 'disabled' | '';
  cta: Array<CtaButtonBlokProps>;
  link: Link;
  name: string;
  text: string;
  title: string;
}

interface TutorJobCardProps {
  blok: TutorJobCardBlokProps;
}

const TutorJobCard = ({ blok }: TutorJobCardProps) => {
  const { clickable = 'disabled', cta: [cta] = [] } = blok;

  return (
    <StoryblokLink
      className="w-full"
      link={clickable === 'enabled' ? blok.link : undefined}
    >
      <div
        {...storyblokEditable(blok)}
        className="flex flex-col w-full bg-GSbase/white rounded-lg border-2 border-solid border-GSsteel/300 break-words p-6 gap-4"
      >
        <div className="text-sm text-GSsteel/500 font-normal">{blok.name}</div>
        <div className="text-lg font-bold font-worksans">{blok.title}</div>
        <div className="text-base font-worksans text-GSbase/black">
          {blok.text}
        </div>
        {cta && (
          <div className="flex">
            <CtaButton
              blok={
                clickable === 'enabled'
                  ? { ...cta, link: { ...cta.link, linktype: 'url', url: '' } }
                  : cta
              }
              size="btn-MD"
            />
          </div>
        )}
      </div>
    </StoryblokLink>
  );
};

export default TutorJobCard;
